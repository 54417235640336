<template>
  <div>

    <FormPageParentsModal 
      ref="parents-modal"
      :form_page_data="local_parent_page_form_data"
      :form_page="parent_form_page"
      @save="save_parent"
    />
    
    <v-row class="mt-4" v-if="form_page && form_page.fields && (form_page.fields.firstname.show || form_page.fields.lastname.show)">
      
      <v-col cols="12" md="6" v-if="form_page.fields.firstname.show">
        
        <memlist-text-input
          id="firstname"
          name="firstname"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.FIRSTNAME')"
          v-model="local_form_page_data.firstname"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.FIRSTNAME')"
          :required="form_page.fields.firstname.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      <v-col cols="12" md="6" v-if="form_page.fields.lastname.show">
        
        <memlist-text-input
          id="lastname"
          name="lastname"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.LASTNAME')"
          v-model="local_form_page_data.lastname"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.LASTNAME')"
          :required="form_page.fields.lastname.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

    </v-row>
    <v-row v-if="form_page.fields.email.show">

      <v-col cols="12" md="6" v-if="form_page.fields.email.show">
        
        <memlist-text-input
          id="email"
          name="email"
          validation_type="EMAIL"
          layout="vertical"
          :title="$t('PUBLIC.FORM.EMAIL')"
          v-model="local_form_page_data.email"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.EMAIL')"
          :required="form_page.fields.email.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_EMAIL')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      <v-col cols="12" md="6" v-if="form_page.fields.email.show">
        
        <memlist-text-input
          id="email2"
          name="email2"
          validation_type="EMAIL"
          layout="vertical"
          :title="$t('PUBLIC.FORM.EMAIL2')"
          v-model="local_form_page_data.email2"
          :validation_function="validate_email2"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.EMAIL2')"
          :required="form_page.fields.email.required"
          :invalid_text="$t('PUBLIC.FORM.EMAIL_MISMATCH')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <v-row v-if="form_page.fields.dob.show">

      <v-col cols="12" md="6" v-if="form_page.fields.dob.show">
        
        <memlist-text-input
          id="dob"
          name="dob"
          type="text"
          validation_type="DOB"
          layout="vertical"
          :title="$t('PUBLIC.FORM.DOB')"
          v-model="local_form_page_data.dob"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.DOB')"
          :required="form_page.fields.dob.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_DOB')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

    </v-row>

    <v-row v-if="form_page.fields.password.show">

      <v-col cols="12" md="6" v-if="form_page.fields.password.show">
        
        <memlist-text-input
          id="password"
          name="password"
          type="password"
          validation_type="PASSWORD"
          layout="vertical"
          :title="$t('PUBLIC.FORM.PASSWORD')"
          v-model="local_form_page_data.password"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.PASSWORD')"
          :required="form_page.fields.password.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_PASSWORD')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      <v-col cols="12" md="6" v-if="form_page.fields.password.show">
        
        <memlist-text-input
          id="password2"
          name="password2"
          validation_type="PASSWORD"
          type="password"
          layout="vertical"
          :title="$t('PUBLIC.FORM.PASSWORD2')"
          v-model="local_form_page_data.password2"
          :validation_function="validate_password2"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.PASSWORD2')"
          :required="form_page.fields.password.required"
          :invalid_text="$t('PUBLIC.FORM.PASSWORD_MISMATCH')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <v-row v-if="form_page.fields.phone.show || form_page.fields.landline.show">

      <v-col cols="12" md="6" v-if="form_page.fields.phone.show">
        
        <memlist-text-input
          id="phone"
          name="phone"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.PHONE')"
          v-model="local_form_page_data.phone"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.PHONE')"
          :required="form_page.fields.phone.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_PHONE')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      <v-col cols="12" md="6" v-if="form_page.fields.landline.show">
        
        <memlist-text-input
          id="landline"
          name="landline"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.LANDLINE')"
          v-model="local_form_page_data.landline"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.LANDLINE')"
          :required="form_page.fields.landline.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_PHONE')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>


      </v-col>
    </v-row>

    <v-row v-if="form_page.fields.public_sex.show">

      <v-col cols="12" md="6" v-if="form_page.fields.public_sex.show">

        <p style="margin-bottom: 6px;">{{ $t('PUBLIC.FORM.PUBLIC_SEX') }}</p>
        
        <v-select
          style="font-size: 12px;"
          v-model="local_form_page_data.public_sex"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="sex_options"
          label=""
          outlined
          dense
          ></v-select>

      </v-col>

    </v-row>

    <v-row v-if="form_page.fields.avd.show || form_page.fields.org_number.show">

      <v-col cols="12" md="6" v-if="form_page.fields.avd.show">
        
        <memlist-text-input
          id="avd"
          name="avd"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.AVD')"
          v-model="local_form_page_data.avd"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.AVD')"
          :required="form_page.fields.avd.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      <v-col cols="12" md="6" v-if="form_page.fields.org_number.show">
        
        <memlist-text-input
          id="org_number"
          name="org_number"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.ORG_NUMBER')"
          v-model="local_form_page_data.org_number"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.ORG_NUMBER')"
          :required="form_page.fields.org_number.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_ORG_NUMBER')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

      
    </v-row>

    <v-row v-if="form_page.fields.cname.show">

      <v-col cols="12" md="6" v-if="form_page.fields.cname.show">
        
        <memlist-text-input
          id="cname"
          name="cname"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.CNAME')"
          v-model="local_form_page_data.cname"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.CNAME')"
          :required="form_page.fields.cname.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>

    </v-row>


    <v-row v-if="form_page.fields.address.show">

      <v-col cols="12" md="6" v-if="form_page.fields.address.show">
        
        <memlist-text-input
          id="address"
          name="address"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.ADDRESS')"
          v-model="local_form_page_data.address"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.ADDRESS')"
          :required="form_page.fields.address.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_ADDRESS')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <v-row v-if="form_page.fields.zipcode.show || form_page.fields.post.show">

      <v-col cols="12" md="6" v-if="form_page.fields.zipcode.show">
        
        <memlist-text-input
          id="zipcode"
          name="zipcode"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.ZIPCODE')"
          v-model="local_form_page_data.zipcode"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.ZIPCODE')"
          :required="form_page.fields.zipcode.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_ZIPCODE')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>


      </v-col>


      <v-col cols="12" md="6" v-if="form_page.fields.post.show">
        
        <memlist-text-input
          id="post"
          name="post"
          type="text"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.POST')"
          v-model="local_form_page_data.post"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.POST')"
          :required="form_page.fields.post.required"
          :invalid_text="$t('PUBLIC.FORM.INVALID_POST')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>


      </v-col>
    </v-row>

    <v-row v-if="form_page.fields.commune_code.show || form_page.fields.region_code.show">

      <v-col cols="12" md="6" v-if="form_page.fields.region_code.show">

        <p style="margin-bottom: 6px;">{{ $t('PUBLIC.FORM.REGION_CODE') }}</p>
        
        <v-select
          style="font-size: 12px;"
          v-model="local_form_page_data.region_code"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="region_code_options"
          label=""
          outlined
          dense
          ></v-select>

      </v-col>


      <v-col cols="12" md="6" v-if="form_page.fields.commune_code.show">
        
        <p style="margin-bottom: 6px;">{{ $t('PUBLIC.FORM.COMMUNE_CODE') }}</p>

        <v-select
          style="font-size: 12px;"
          v-model="local_form_page_data.commune_code"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="commune_code_options"
          label=""
          outlined
          dense
          ></v-select>

      </v-col>
    </v-row>

    <v-row>

      <v-col cols="12" md="6" v-if="form_page.fields.country.show">

        <p style="margin-bottom: 6px;">{{ $t('PUBLIC.FORM.COUNTRY') }}</p>
        
        <v-select
          style="font-size: 12px;"
          v-model="local_form_page_data.country"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="country_options"
          label=""
          outlined
          dense
          ></v-select>

      </v-col>

    </v-row>


    <div v-if="invoice_fields_visible">
      <h4>{{ $t('PUBLIC.FORM.INVOICE_FIELDS') }}</h4>

      <v-row v-if="form_page.fields.invoice_email.show || form_page.fields.invoice_phone.show">

        <v-col cols="12" md="6" v-if="form_page.fields.invoice_email.show">
          
          <memlist-text-input
            id="invoice_email"
            name="invoice_email"
            validation_type="EMAIL"
            layout="vertical"
            :title="$t('PUBLIC.FORM.EMAIL')"
            v-model="local_form_page_data.invoice_email"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.EMAIL')"
            :required="form_page.fields.invoice_email.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_EMAIL')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

        <v-col cols="12" md="6" v-if="form_page.fields.invoice_phone.show">
          
          <memlist-text-input
            id="invoice_phone"
            name="invoice_phone"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.PHONE')"
            v-model="local_form_page_data.invoice_phone"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.PHONE')"
            :required="form_page.fields.invoice_phone.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>


      <v-row class="mt-4" v-if="form_page && form_page.fields && (form_page.fields.invoice_firstname.show || form_page.fields.invoice_lastname.show)">
        
        <v-col cols="12" md="6" v-if="form_page.fields.invoice_firstname.show">
          
          <memlist-text-input
            id="invoice_firstname"
            name="invoice_firstname"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.FIRSTNAME')"
            v-model="local_form_page_data.invoice_firstname"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.FIRSTNAME')"
            :required="form_page.fields.invoice_firstname.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

        <v-col cols="12" md="6" v-if="form_page.fields.invoice_lastname.show">
          
          <memlist-text-input
            id="invoice_lastname"
            name="invoice_lastname"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.LASTNAME')"
            v-model="local_form_page_data.invoice_lastname"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.LASTNAME')"
            :required="form_page.fields.invoice_lastname.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

      </v-row>

      <v-row class="mt-4" v-if="form_page && form_page.fields && (form_page.fields.invoice_address.show)">
        
        <v-col cols="12" md="6" v-if="form_page.fields.invoice_address.show">
          
          <memlist-text-input
            id="invoice_address"
            name="invoice_address"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.ADDRESS')"
            v-model="local_form_page_data.invoice_address"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.ADDRESS')"
            :required="form_page.fields.invoice_address.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

      </v-row>

      <v-row class="mt-4" v-if="form_page && form_page.fields && (form_page.fields.invoice_zipcode.show || form_page.fields.invoice_post.show)">
        
        <v-col cols="12" md="6" v-if="form_page.fields.invoice_zipcode.show">
          
          <memlist-text-input
            id="invoice_zipcode"
            name="invoice_zipcode"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.ZIPCODE')"
            v-model="local_form_page_data.invoice_zipcode"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.ZIPCODE')"
            :required="form_page.fields.invoice_zipcode.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

        <v-col cols="12" md="6" v-if="form_page.fields.invoice_post.show">
          
          <memlist-text-input
            id="invoice_post"
            name="invoice_post"
            validation_type="TEXT"
            layout="vertical"
            :title="$t('PUBLIC.FORM.POST')"
            v-model="local_form_page_data.invoice_post"
            :validate="force_validate"
            :placeholder="$t('PUBLIC.FORM.POST')"
            :required="form_page.fields.invoice_post.required"
            :invalid_text="$t('PUBLIC.FORM.INVALID_TEXT')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>

      </v-row>

    </div>

    
    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import FormPageParentsModal from '@/view/pages/public/forms/pages/FormPageParentsModal.vue';
import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';


export default {
  name: 'FormPagePersonnr',
  mixins: [ toasts ],

  components: {
    FormPageParentsModal
  },

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    },
    form_pages: { /* we need to find the parent page */
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    sex_options() {
      return [
        { value: 'M', text: this.$t('PUBLIC.FORM.MALE') },
        { value: 'F', text: this.$t('PUBLIC.FORM.FEMALE') },
        { value: 'O', text: this.$t('PUBLIC.FORM.OTHER') }
      ];
    },
    invoice_fields_visible() {
      return this.form_page.fields.invoice_address?.show || this.form_page.fields.invoice_zipcode?.show || this.form_page.fields.invoice_post?.show || this.form_page.fields.invoice_commune_code?.show || this.form_page.fields.invoice_region_code?.show || this.form_page.fields.invoice_country?.show;
    },
    country_options() {

      var countries_options = [];

      for (var i = 0; i < this.$t('COUNTRIES').length; i++) {
        countries_options.push({ value: this.$t('COUNTRIES')[i].key, text: this.$t('COUNTRIES')[i].value });
      }

      return countries_options;
    }
  },

  data() {
    return {
      // -- parent data
      local_parent_page_form_data: {},
      parent_form_page: {},
      // -- parent data
      

      local_form_page_data: {},

      commune_code_options: [],
      region_code_options: [],

      regions: {},

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    this.load_regions();

    console.log('FormPagePersonDetails mounted() form_page', this.form_page);
    console.log('FormPagePersonDetails mounted() form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data };

    if (!this.local_form_page_data.country) {
      this.local_form_page_data.country = 'SE';
    }
  },
  watch: {
    form_page_data: {
      handler(newValue) {
        this.local_form_page_data = { ...this.form_page_data }
      },
      deep: true
    },

    'local_form_page_data.region_code'(val) {
      if (!val) {
        return;
      }

      this.set_communes(this.regions[this.local_form_page_data.region_code].communes)
    }
  },
  methods: {
    validate_password2(val) {
      if (this.local_form_page_data.password !== val) {
        return false;
      }

      return true;
    },
    validate_email2(val) {
      
      if (this.local_form_page_data.email !== val) {
        return false;
      }

      return true;
    },

    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    skip_clicked() {},

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        this.regions = res.data.regions;
        this.region_code_options = [];

        for (var k in this.regions) {
          this.region_code_options.push({ value: k, text: this.regions[k].region });
        }

        this.region_code_options.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

        if (this.local_form_page_data.region_code && this.local_form_page_data.region_code in this.regions) {
          this.set_communes(this.regions[this.local_form_page_data.region_code].communes);
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },


    set_communes(communes) {
      this.commune_code_options = [];

      for (var k in communes) {
        this.commune_code_options.push({ value: k, text: communes[k] });
      }

      this.commune_code_options.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },

    calc_age_from_dob(dob) {
      const personnr = formatPersonnr(dob);

      if (personnr.success) {
        return dayjs().diff(
          personnr.year + '-' + personnr.month + '-' + personnr.day,
          'years'
        );
      }

      return 18;
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          // if we are underage, show the parents form
          const age = this.calc_age_from_dob(this.local_form_page_data.dob);

          const parent = getKeyValue('form_parent');
          
          if (this.form_page.age_required > age && (!parent || !parent.valid)) {
            // show the parent modal
            this.$refs['parents-modal'].show();
            return;
          }

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>


<style lang="css" scoped>

:deep .v-select__selection {
  font-size: 12px;
}

</style>