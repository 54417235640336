<template>
    <div>
      
      <div class="page-container">
        <div class="section-container">
          <FormShopCompanySelection
            :companies="local_companies"
            @selected_membership="selected_membership"
          />
        </div>

        <div class="section-container" v-if="local_extra_items && local_extra_items.length > 0">
          <FormShopExtraSelection 
            :extra_items="local_extra_items" 
            @selected="selected_extra_items"
          />
        </div>


        <div class="section-container">
          <FormShopCartSummaryTable 
            :cart_items="cart_items" 
            @remove-item="removeCartItem" 
          />
        </div>

      </div>
      
      
      <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>
  
      <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
        <b-button
          v-if="form_page.index != 0"
          class="mt-4 mr-4"
          variant="outline-secondary"
          @click.prevent="on_back"
        >
          {{ $t('COMMON.BACK') }}
        </b-button>
        
        <b-button
          class="mt-4"
          variant="primary"
          :type="type"
          :disabled="disabled || spinning"
          @click.prevent="on_click"
        >
          <b-spinner v-if="spinning" small type="grow"></b-spinner>
          {{ $t('COMMON.CONTINUE') }}
        </b-button>
      </div>
  
    </div>
  </template>
  
<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import FormShopCartSummaryTable from './shop/FormShopCartSummaryTable.vue';
import FormShopExtraSelection from './shop/FormShopExtraSelection.vue';
import FormShopCompanySelection from './shop/FormShopCompanySelection.vue';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';

  
export default {
  name: 'FormPageShop',
  mixins: [ toasts ],

  components: { 
    FormShopCartSummaryTable, 
    FormShopCompanySelection, 
    FormShopExtraSelection 
  },

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {

      local_extra_items: [
        /*{
          shop_item_id: 11,
          selected: false,
          name: 'Extra Item 1',
          amount: '5',
          currency: 'SEK',
          description: 'Description for extra item 1',
          img_url: 'http://localhost:3020/dlfile/7TZe1m6WW6kthf397sUy5OFPSKUvKGH6Cwa6hXW4QrbdNSbkL0pqo9EwFyLIr0qZ',
        },*/
      ],
      local_companies: [
        /*{
          name: "Company A",
          company_id: 'a',
          show_error: false,
          items: [
            {
              shop_item_id: 1,
              name: 'Item 1 from Company A',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 1 from company A, this is a longer description, this is a longer description, this is a longer description this is a longer description.',
              dateFrom: '2024-01-01',
              dateTo: '2024-12-31',
              selected: false
            },
            {
              shop_item_id: 2,
              name: 'Item 2 from Company A',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 2 from company A',
              dateFrom: '2024-02-01',
              dateTo: '2024-11-30',
              selected: false
            }
          ],
        },*/
      ],

      cart_items: [
        
      ],

      local_form_page_data: {},

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    console.log('FormPageShop mounted() form_page', this.form_page);
    console.log('FormPageShop mounted() form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data };

    const company_ids = getKeyValue('form_company_ids');

    this.get_shop_for_selection(company_ids);
  },
  watch: {
    form_page_data: {
      handler(newValue) {
        this.local_form_page_data = { ...this.form_page_data }
      },
      deep: true
    }
  },
  computed: {
    currency() {

      if (!this.companies_with_shop) {
        return 'SEK';
      }

      for (const comp of this.companies_with_shop) {
        const item_with_currency = comp.shop_items.find(item => item.currency) || { currency: 'SEK' };

        return item_with_currency.currency;
      }

      return 'SEK';
    },
  },
  methods: {

    validate() {
      for (const company of this.local_companies) {
        // check if there is a selected item
        const selected_item = company.items.find(item => item.selected);

        if (!selected_item) {
          company.show_error = true;
        }
      }

      window.scrollTo(0,0);

      this.local_companies = [ ...this.local_companies ];
    },
    selected_extra_items(shop_item_ids) {
      for (const item of this.local_extra_items) {
        item.selected = false;

        const exists = shop_item_ids.find(i => i === item.shop_item_id);
        item.selected = !!exists;

        if (item.selected) {
          const cart_item = this.cart_items.find(i => i.shop_item_id === item.shop_item_id);
          if (!cart_item) {
            this.cart_items.push({ ...item, quantity: 1 });
          }
        }
        else {
          this.cart_items = this.cart_items.filter(i => i.shop_item_id !== item.shop_item_id);
        }
      }

      this.local_extra_items = [ ...this.local_extra_items ];

      this.local_form_page_data.cart = this.cart_items;

      this.$emit('change', this.local_form_page_data);
    },

    selected_membership(company_id, shop_item, shop_item_id, previous_shop_item_id) {
      const company = this.local_companies.find(item => item.company_id === company_id);
      const item = company.items.find(item => item.shop_item_id === shop_item_id);

      if (!item) {
        console.error('item not found', company, item, shop_item_id);
        
        return;
      }

      item.selected = true;
      company.show_error = false;

      this.local_companies = [ ...this.local_companies ];

      this.cart_items = this.cart_items.filter(item => item.shop_item_id !== previous_shop_item_id);

      this.cart_items.push({ ...item, quantity: 1 });

      this.local_form_page_data.cart = this.cart_items;

      this.$emit('change', this.local_form_page_data);
    },
    removeCartItem(index, shop_item) {
      this.cart_items.splice(index, 1);

      for (const company of this.local_companies) {
        for (const item of company.items) {
          if (item.shop_item_id === shop_item.shop_item_id) {
            item.selected = false;
          }
        }
      }

      for (const item of this.local_extra_items) {
        if (item.shop_item_id === shop_item.shop_item_id) {
          item.selected = false;
        }
      }

      this.local_extra_items = [ ...this.local_extra_items ];

      this.local_companies = [ ...this.local_companies ];
    },


    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    skip_clicked() {},

    /*
      based on the company selections, we need to fetch the shop items
      and possibly get some extra items if possible
    */
    async get_shop_for_selection(company_ids) {
      if (!company_ids || company_ids.length === 0) {
        throw 'get_shop_for_selection: no company ids';
      }

      try {
        const res = await axios.post(`/company/list_shop`, { company_ids: company_ids });

        /*
        [
          { 
            company: { company_id: 'abc123', name: 'test' },
            shop_items: [
              { shop_item_id: 1, name: 'test item' }
            ]
          }
        ]
        */

        if (res.status !== 200) {
          throw 'get_shop_for_selection: invalid status ' + res.status;
        }

        this.local_companies = [];

        const companies = res.data;

        for (const company of companies) {
          this.local_companies.push({
            company_id: company.company.company_id,
            name: company.company.name,
            items: company.shop_items.map(item => ({ ...item, selected: false })),
            show_error: false,
          });
        }

        console.log(this.local_companies)
      }
      catch (err) {
        console.error('get_shop_for_selection', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_GET'));
      }
    },

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>

<style scoped>
/* Container for both ItemSelector and CartSummary */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Common container styling for ItemSelector and CartSummary */
.section-container {
  width: 100%;
  max-width: 1200px; /* Limit the size on larger screens */
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media (max-width: 768px) {
  /* On mobile, the container will take the full width */
  .section-container {
    width: 100%;
    padding: 0;
    box-shadow: none; /* Optional: remove shadow on mobile for a flatter design */
    border-radius: 0;
  }
}
</style>