<template>
  <div>

    <b-row v-if="extra_visibility_option === 'search'" class="mt-8 mr-16">
      <b-col>
        <b-form-group
          class="mr-16"
          :label="$t('ADVANCED_SEARCH.COMPANIES')">

          <Multiselect
            class="ml-2 multiselect-blue"
            v-model="company_ids"
            ref="companies_multiselect"
            mode="tags"
            :placeholder="$t('ADVANCED_SEARCH.ALL_COMPANIES')"
            :close-on-select="false"
            :filter-results="false"
            :min-chars="0"
            :resolve-on-load="false"
            :delay="0"
            :searchable="true"
            @input="multi_changed"
            :options="async function(query) {
              return await fetch_company_options(query);
            }"
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-else class="mt-8">
      <b-col>
        <div class="checkbox-list">
          <template v-for="(company, i) of companies">
            <div :key="i">
              <div class="d-flex align-items-center mb-6">
                <label class="radio radio-outline radio-success mr-5">
                  <input
                    type="radio"
                    v-model="extra_company_id"
                    :value="company.company_id"
                    @change="extra_company_changed"
                  />
                  <span></span>
                </label>
                <span class="mr-2"></span>{{ company.name }}
              </div>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </div>

</template>


<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}

.reg-header h4 {
  text-transform: uppercase;
  padding: 8px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  color: #6c6c6c;
}

.reg-header {
  padding: 4px;
  background-color: #efeff2;
}

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

export default {
  name: 'extra-company-single-option',
  mixins: [ toasts ],
  props: ['companies','extra_visibility_option'],
  emits: ['changed'],
  components: {
    Multiselect
  },
  watch: {
    companies(newVal) {
      if (newVal?.length) {
        if (this.$refs.companies_multiselect) {
          if (Array.isArray(this.$refs.companies_multiselect)) {
            this.$refs.companies_multiselect[0].refreshOptions()
          }
          else {
            this.$refs.companies_multiselect.refreshOptions()
          }
        }
      }
    }
  },
  computed:{

  },
  async mounted() {
    if (this.$refs.companies_multiselect) {
      if (Array.isArray(this.$refs.companies_multiselect)) {
        this.$refs.companies_multiselect[0].refreshOptions()
      }
      else {
        this.$refs.companies_multiselect.refreshOptions()
      }
    }
  },
  methods: {
    multi_changed() {
      this.$emit('changed', this.company_ids);
    },
    async fetch_company_options(query) {
      if (!this.companies?.length) {
        return []
      }

      let res_companies = [...this.companies];

      if (query?.length) {
        res_companies = this.companies.filter(item => item.name.toLowerCase().includes(query?.toLowerCase()));
      }

      return res_companies.map(item => ({ label: item.name, value: item.company_id }));
    },

    extra_company_changed() {
      this.$emit('changed', [this.extra_company_id]);
    }
  },
  data() {
    return {
      extra_company_id: null,

      company_ids: []
    }
  }
};
</script>
